const name = 'PROPOSAL';

export const FETCH_PROPOSAL_LIST = `${name}/FETCH_PROPOSAL_LIST`;
export const FETCH_PROPOSAL_LIST_START = `${FETCH_PROPOSAL_LIST}/START`;
export const FETCH_PROPOSAL_LIST_END = `${FETCH_PROPOSAL_LIST}/END`;
export const FETCH_PROPOSAL_LIST_FAILURE = `${FETCH_PROPOSAL_LIST}/FAILURE`;
export const FETCH_PROPOSAL_LIST_SUCCESS = `${FETCH_PROPOSAL_LIST}/SUCCESS`;

export const FETCH_PROPOSAL = `${name}/FETCH_PROPOSAL`;
export const FETCH_PROPOSAL_START = `${FETCH_PROPOSAL}/START`;
export const FETCH_PROPOSAL_END = `${FETCH_PROPOSAL}/END`;
export const FETCH_PROPOSAL_FAILURE = `${FETCH_PROPOSAL}/FAILURE`;
export const FETCH_PROPOSAL_SUCCESS = `${FETCH_PROPOSAL}/SUCCESS`;

export const CREATE_PROPOSAL = `${name}/CREATE_PROPOSAL`;
export const CREATE_PROPOSAL_START = `${CREATE_PROPOSAL}/START`;
export const CREATE_PROPOSAL_END = `${CREATE_PROPOSAL}/END`;
export const CREATE_PROPOSAL_FAILURE = `${CREATE_PROPOSAL}/FAILURE`;
export const CREATE_PROPOSAL_SUCCESS = `${CREATE_PROPOSAL}/SUCCESS`;

export const CREATE_PENDING_PROPOSAL = `${name}/CREATE_PENDING_PROPOSAL`;
export const CREATE_PENDING_PROPOSAL_START = `${CREATE_PENDING_PROPOSAL}/START`;
export const CREATE_PENDING_PROPOSAL_END = `${CREATE_PENDING_PROPOSAL}/END`;
export const CREATE_PENDING_PROPOSAL_FAILURE = `${CREATE_PENDING_PROPOSAL}/FAILURE`;
export const CREATE_PENDING_PROPOSAL_SUCCESS = `${CREATE_PENDING_PROPOSAL}/SUCCESS`;

export const SET_PENDINGPROPOSAL = `${name}/SET_PENDINGPROPOSAL`;

export const SET_VOTESUPPORT = `${name}/SET_VOTESUPPORT`;

export const VOTE_PROPOSAL = `${name}/VOTE_PROPOSAL`;
export const VOTE_PROPOSAL_START = `${VOTE_PROPOSAL}/START`;
export const VOTE_PROPOSAL_END = `${VOTE_PROPOSAL}/END`;
export const VOTE_PROPOSAL_FAILURE = `${VOTE_PROPOSAL}/FAILURE`;
export const VOTE_PROPOSAL_SUCCESS = `${VOTE_PROPOSAL}/SUCCESS`;

export const RESET_ERROR_MSG = `${name}/RESET_ERROR_MSG`;
export const RESET = `${name}/RESET`;

export const CANCEL_PROPOSAL = `${name}/CANCEL_PROPOSAL`;
export const CANCEL_PROPOSAL_START = `${CANCEL_PROPOSAL}/START`;
export const CANCEL_PROPOSAL_END = `${CANCEL_PROPOSAL}/END`;
export const CANCEL_PROPOSAL_FAILURE = `${CANCEL_PROPOSAL}/FAILURE`;
export const CANCEL_PROPOSAL_SUCCESS = `${CANCEL_PROPOSAL}/SUCCESS`;

export const UPDATE_PROPOSAL_STATE = `${name}/UPDATE_PROPOSAL_STATE`;
export const UPDATE_PROPOSAL_STATE_START = `${UPDATE_PROPOSAL_STATE}/START`;
export const UPDATE_PROPOSAL_STATE_END = `${UPDATE_PROPOSAL_STATE}/END`;
export const UPDATE_PROPOSAL_STATE_FAILURE = `${UPDATE_PROPOSAL_STATE}/FAILURE`;
export const UPDATE_PROPOSAL_STATE_SUCCESS = `${UPDATE_PROPOSAL_STATE}/SUCCESS`;

export const QUEUE_PROPOSAL = `${name}/QUEUE_PROPOSAL`;
export const QUEUE_PROPOSAL_START = `${QUEUE_PROPOSAL}/START`;
export const QUEUE_PROPOSAL_END = `${QUEUE_PROPOSAL}/END`;
export const QUEUE_PROPOSAL_FAILURE = `${QUEUE_PROPOSAL}/FAILURE`;
export const QUEUE_PROPOSAL_SUCCESS = `${QUEUE_PROPOSAL}/SUCCESS`;

export const EXECUTE_PROPOSAL = `${name}/EXECUTE_PROPOSAL`;
export const EXECUTE_PROPOSAL_START = `${EXECUTE_PROPOSAL}/START`;
export const EXECUTE_PROPOSAL_END = `${EXECUTE_PROPOSAL}/END`;
export const EXECUTE_PROPOSAL_FAILURE = `${EXECUTE_PROPOSAL}/FAILURE`;
export const EXECUTE_PROPOSAL_SUCCESS = `${EXECUTE_PROPOSAL}/SUCCESS`;