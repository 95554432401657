import Modal from './Modal';
import './AccountModal.scss';
import { connect } from 'react-redux';
import { truncateAddress, copyToClipboard } from '../utils/Utils';
import { useState } from 'react';
import config from '../Config';


const AccountModal = props => {
  const { address } = props;
  const [copied, setCopied] = useState(false);

  const copyAddress = () => {
    copyToClipboard(address);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000)
  }

  return <Modal className="account-modal" onClose={props.onClose}>
    <div className="modal-title">Account</div>
    <div className="modal">
      <div className="account-modal__card">
        <div className="account-modal__card--title">
          Connected with MetaMask
        </div>
        <div className="account-modal__card--address">
          {truncateAddress(address)}
        </div>
        <div className="account-modal__card--links">
          {copied ? <div className='card-link copied'>Copied!</div> :
            <div className='card-link copy' onClick={copyAddress}>Copy Address</div>}
          <div className='card-link view'>
            <a href={`${config.explorerUrl}/address/${address}`}
              target="_blank" rel="noopener noreferrer">
              View On Explorer
            </a>
          </div>
        </div>
      </div>
      <div className='account-modal__transactions'>
        Your transactions will appear here...
      </div>
    </div>
  </Modal>
}

const mapStateToProps = state => ({
  address: state.metamask.address,
});

export default connect(mapStateToProps)(AccountModal);