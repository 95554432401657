import * as actionTypes from "../types/Proposal";

const INITIAL_STATE = {
  proposalList: [],
  isFetchingProposalList: false,

  proposal: {
    id: null,
    proposer: '',
    action: '',
    title: '',
    status: '',
    details: '',
    description: ''
  },
  isFetchingProposal: false,

  pendingProposal: {
    proposer: '',
    action: '',
    title: '',
    details: '',
    description: ''
  },
  isCreatingProposal: false,
  isCreatingPendingProposal: false,
  isCreatedPendingProposal: false,
  isCreatedProposal: false,
  errMsg: '',
  voteSupport: null,
  isVotingProposal: false,
  isVoted: false,
  isCancelingProposal: false,
  isCanceled: false,
  isUpdatingState: false,
  isUpdatedState: false,
  isQueued: false,
  isQueuing: false,
  isExecuted: false,
  isExecuting: false,
  proposalETA: null
};


export const proposalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //FETCH PROPOSAL LIST
    case actionTypes.FETCH_PROPOSAL_LIST_START: {
      return Object.assign({}, state, {
        isFetchingProposalList: true
      });
    }
    case actionTypes.FETCH_PROPOSAL_LIST_END: {
      return Object.assign({}, state, {
        isFetchingProposalList: false
      });
    }
    case actionTypes.FETCH_PROPOSAL_LIST_SUCCESS: {
      let body = action.response.body;
      let proposalList = body;

      return Object.assign({}, state, {
        proposalList: proposalList,
      });
    }
    //FETCH PROPOSAL BY ID
    case actionTypes.FETCH_PROPOSAL_START: {
      return Object.assign({}, state, {
        isFetchingProposal: true
      });
    }
    case actionTypes.FETCH_PROPOSAL_END: {
      return Object.assign({}, state, {
        isFetchingProposal: false
      });
    }
    case actionTypes.FETCH_PROPOSAL_SUCCESS: {
      let body = action.response.body;
      let proposal = body;

      return Object.assign({}, state, {
        proposal,
      });
    }

    //CREATE PROPOSAL 
    case actionTypes.CREATE_PROPOSAL_START: {
      return Object.assign({}, state, {
        isCreatingProposal: true,
        pendingProposal: action.metadata.proposalData,
        errMsg: ""
      });
    }
    case actionTypes.CREATE_PROPOSAL_END: {
      return Object.assign({}, state, {
        isCreatingProposal: false
      });
    }
    case actionTypes.CREATE_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        pendingProposal: null,
        isCreatedProposal: true
      });
    }
    case actionTypes.CREATE_PROPOSAL_FAILURE: {
      return Object.assign({}, state, {
        pendingProposal: null,
        errMsg: action.errMsg
      });
    }


    //CREATE PENDING PROPOSAL 
    case actionTypes.CREATE_PENDING_PROPOSAL_START: {
      return Object.assign({}, state, {
        isCreatingPendingProposal: true,
        isCreatedPendingProposal: false,
        pendingProposal: action.metadata.proposalData,
        errMsg: ""
      });
    }
    case actionTypes.CREATE_PENDING_PROPOSAL_END: {
      return Object.assign({}, state, {
        isCreatingPendingProposal: false
      });
    }
    case actionTypes.CREATE_PENDING_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        isCreatedPendingProposal: true
      });
    }

    case actionTypes.SET_PENDINGPROPOSAL: {
      return Object.assign({}, state, {
        pendingProposal: action.pendingProposal
      })
    }

    case actionTypes.SET_VOTESUPPORT: {
      return Object.assign({}, state, {
        voteSupport: action.voteSupport
      })
    }

    //VOTE PROPOSAL 
    case actionTypes.VOTE_PROPOSAL_START: {
      return Object.assign({}, state, {
        isVotingProposal: true,
        errMsg: ""
      });
    }
    case actionTypes.VOTE_PROPOSAL_END: {
      return Object.assign({}, state, {
        isVotingProposal: false
      });
    }
    case actionTypes.VOTE_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        isVoted: true,
      });
    }
    case actionTypes.VOTE_PROPOSAL_FAILURE: {
      return Object.assign({}, state, {
        errMsg: action.errMsg
      });
    }

    //CANCEL PROPOSAL 
    case actionTypes.CANCEL_PROPOSAL_START: {
      return Object.assign({}, state, {
        isCancelingProposal: true,
        errMsg: ""
      });
    }
    case actionTypes.CANCEL_PROPOSAL_END: {
      return Object.assign({}, state, {
        isCancelingProposal: false
      });
    }
    case actionTypes.CANCEL_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        proposal: Object.assign({}, state.proposal, {
          status: '2'
        })
      });
    }
    case actionTypes.CANCEL_PROPOSAL_FAILURE: {
      return Object.assign({}, state, {
        errMsg: action.errMsg
      });
    }

    //UPDATE PROPOSAL STATE 
    case actionTypes.UPDATE_PROPOSAL_STATE_START: {
      return Object.assign({}, state, {
        isUpdatingState: true,
        errMsg: ""
      });
    }
    case actionTypes.UPDATE_PROPOSAL_STATE_END: {
      return Object.assign({}, state, {
        isUpdatingState: false
      });
    }
    case actionTypes.UPDATE_PROPOSAL_STATE_SUCCESS: {
      return Object.assign({}, state, {
        isUpdatedState: true,
      });
    }
    case actionTypes.UPDATE_PROPOSAL_STATE_FAILURE: {
      return Object.assign({}, state, {
        errMsg: action.errMsg
      });
    }

    //QUEUE PROPOSAL 
    case actionTypes.QUEUE_PROPOSAL_START: {
      return Object.assign({}, state, {
        isQueuing: true,
        errMsg: ""
      });
    }
    case actionTypes.QUEUE_PROPOSAL_END: {
      return Object.assign({}, state, {
        isQueuing: false
      });
    }
    case actionTypes.QUEUE_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        isQueued: true
      });
    }
    case actionTypes.QUEUE_PROPOSAL_FAILURE: {
      return Object.assign({}, state, {
        errMsg: action.errMsg
      });
    }

    //EXECUTE PROPOSAL 
    case actionTypes.EXECUTE_PROPOSAL_START: {
      return Object.assign({}, state, {
        isExecuted: false,
        isExecuting: true,
        errMsg: ""
      });
    }
    case actionTypes.EXECUTE_PROPOSAL_END: {
      return Object.assign({}, state, {
        isExecuting: false
      });
    }
    case actionTypes.EXECUTE_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        isExecuted: true
      });
    }
    case actionTypes.EXECUTE_PROPOSAL_FAILURE: {
      return Object.assign({}, state, {
        errMsg: action.errMsg
      });
    }

    //RESET ERROR MSG
    case actionTypes.RESET_ERROR_MSG: {
      return Object.assign({}, state, {
        errMsg: ""
      })
    }

    // RESET
    case actionTypes.RESET: {
      return Object.assign({}, state, {
        errMsg: "",
        isVoted: false,
        isCanceled: false,
        isCreatedProposal: false,
        isUpdatedState: false,
      })
    }

    default: {
      return state
    }
  }
};

