const BASE_URL = process.env.REACT_APP_API_HOST || "http://localhost:8080";

const DEFAULT_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

//
//Helpers
//

export function isResponseSuccessful(response) {
  let { status } = response;

  return (status === 200 || status === 201 || status === 202 || status === 204);
}

function objectToQueryString(object) {
  if (!object) {
    return "";
  }

  let queryString = Object.keys(object).map(function (key) {
    let val = object[key];
    if (val) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
    }
    return ""
  }).join('&');

  if (queryString.length > 0) {
    return "?" + queryString;
  }
  else {
    return "";
  }
}

//
//Builders
//

function buildHeaders(additionalHeaders) {
  //TODO inject auth headers here...
  return Object.assign(DEFAULT_HEADERS, additionalHeaders);
}

function buildURL(path, queryParams) {
  let url = null;

  if (path.startsWith("http://") || path.startsWith("https://")) {
    url = path + objectToQueryString(queryParams);
  }
  else {
    url = BASE_URL + path + objectToQueryString(queryParams);
  }

  return url;
}

function sendRequest(path, method, additionalHeaders, queryParams, body) {
  let url = buildURL(path, queryParams);
  let headers = buildHeaders(additionalHeaders);

  let opts = {
    method: method,
    headers: headers,
  };

  if (body) {
    opts['body'] = JSON.stringify(body);
  }

  return fetch(url, opts);
}


//
//Convenience requests
//

function GET(path, headers, queryParams) {
  return sendRequest(path, "GET", headers, queryParams);
}

// function PUT(path, headers, queryParams, body) {
//   return sendRequest(path, "PUT", headers, queryParams, body);
// }

function POST(path, headers, queryParams, body) {
  return sendRequest(path, "POST", headers, queryParams, body);
}

// function DELETE(path, headers, queryParams, body) {
//   return sendRequest(path, "DELETE", headers, queryParams, body);
// }

export default class Api {

  //
  //Proposals
  //

  static fetchProposalList(queryParams) {
    let path = `/proposal-list`;
    return GET(path, null, queryParams)
  }

  static fetchProposal(id, queryParams) {
    let path = `/proposal/${id}`;
    return GET(path, null, queryParams);
  }

  static createProposal(proposal, queryParams) {
    let path = `/proposal/${proposal.id}`;
    return POST(path, null, queryParams, proposal);
  }

  static updateProposalStatus(id, status, queryParams) {
    let path = `/proposal/status/${id}`;
    return POST(path, null, queryParams, { status });
  }

  static createPendingProposal(proposal, queryParams) {
    let path = `/pending-proposal/${proposal.proposer}`;
    return POST(path, null, queryParams, proposal);
  }

  //
  //Smart Contract
  //

  static callSmartContract(body, queryParams) {
    let path = "https://api-wallet.thetatoken.org/smart-contract/call";

    return POST(path, null, queryParams, body);
  }

  static executeSmartContract(body, queryParams) {
    let path = "https://api-wallet.thetatoken.org/tx";

    return POST(path, null, queryParams, body);
  }

  //
  //Transaction
  //

  static fetchTransactionById(id, queryParams) {
    let host = process.env.REACT_APP_EXPLORER_API_HOST || 'https://localhost';
    let path = `${host}:8443/api/transaction/${id}`;
    console.log('path:', path);

    return GET(path, null, queryParams);
  }

}