import {
  SET_NETWORKID, SET_PROVIDER, SET_ADDRESS, SET_ISCONNECTING,
  SET_HASERROR, FETCH_BALANCE
  // SET_BALANCE, SET_ISFETCHINGBALANCE,
} from "../types/Metamask";
import { smartContractFetchRPC } from './Api';
import config from '../../Config';
import web3 from '../../utils/Web3';
import { TDropStakingAbi, TDropStakingAddress } from '../../constants/Contracts';

export function setNetworkId(networkId) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_NETWORKID,
      networkId: networkId
    }
    );
  };
}

export function setProvider(provider) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_PROVIDER,
      provider: provider
    })
  }
}

export function setAddress(address) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_ADDRESS,
      address: address,
    })
  }
}

export function setIsConnecting(isConnecting) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_ISCONNECTING,
      isConnecting: isConnecting,
    })
  }
}

export function setHasError(hasError) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_HASERROR,
      hasError: hasError
    })
  }
}
export async function fetchBalanceAsync(address) {
  const contractAddress = TDropStakingAddress[config.defaultThetaChainID];
  const contract = new web3.eth.Contract(TDropStakingAbi, contractAddress);
  const blockNum = await web3.eth.getBlockNumber();
  return smartContractFetchRPC(FETCH_BALANCE, {
    fn: contract.methods.getPriorVotes,
    inputValues: [address, blockNum],
    callData: {}
  })
}
export function fetchBalance(address) {
  return function (dispatch, getState) {
    fetchBalanceAsync(address).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}