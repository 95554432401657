import { combineReducers } from 'redux';
import { metamaskReducer } from './Metamask';
import { proposalReducer } from './Proposal';
import { modalReducer } from './Modal';

export const rootReducer = combineReducers({
  metamask: metamaskReducer,
  proposal: proposalReducer,
  modal: modalReducer,
});
