import Api, { isResponseSuccessful } from '../../services/Api'
import Theta from '../../libs/Theta';
import ThetaJS from '../../libs/thetajs.esm';
import get from 'lodash/get';
import map from 'lodash/map';
import { ethers } from "ethers";

export function reduxFetch(baseAction, apiFn, metadata = {}, opts = {}) {
  let { onSuccess, onError } = opts;

  return async function (dispatch, getState) {
    dispatch({
      type: `${baseAction}/START`,
      metadata: metadata
    });

    try {
      let response = await apiFn();

      if (response) {
        let responseJSON = await response.json();

        response = {
          status: response.status,
          body: responseJSON
        }
      }

      if (isResponseSuccessful(response)) {
        dispatch({
          type: `${baseAction}/SUCCESS`,
          response: response,
          metadata: metadata
        });

        if (onSuccess) {
          onSuccess(dispatch, response);
        }
      }
      else {
        dispatch({
          type: `${baseAction}/FAILURE`,
          response: null,
          metadata: metadata
        });

        if (onError) {
          onError(dispatch, response);
        }
      }


    }
    catch (e) {
      // Failed to parse
      dispatch({
        type: `${baseAction}/FAILURE`,
        response: null,
        metadata: metadata
      });

      if (onError) {
        onError(dispatch, null);
      }
    }
    finally {
      dispatch({
        type: `${baseAction}/END`,
        metadata: metadata
      });
    }
  };
}


export function smartContractFetch(baseAction, metadata = {}, opts = {}) {
  let { onSuccess, onError } = opts;
  const { functionData, abi, inputValues, address } = metadata;

  return async function (dispatch, getState) {
    dispatch({
      type: `${baseAction}/START`,
      metadata: metadata
    });

    const iface = new ethers.utils.Interface(abi || []);
    const senderSequence = 1;
    const functionInputs = get(functionData, ['inputs'], []);
    const functionOutputs = get(functionData, ['outputs'], []);
    const functionSignature = iface.getSighash(functionData.name)

    const inputTypes = map(functionInputs, ({ name, type }) => {
      return type;
    });
    try {
      var abiCoder = new ethers.utils.AbiCoder();
      var encodedParameters = abiCoder.encode(inputTypes, inputValues).slice(2);;
      const gasPrice = Theta.getTransactionFee(); //feeInTFuelWei;
      const gasLimit = 2000000;
      const data = functionSignature + encodedParameters;
      const tx = Theta.unsignedSmartContractTx({
        from: address,
        to: address,
        data: data,
        value: 0,
        transactionFee: gasPrice,
        gasLimit: gasLimit
      }, senderSequence);
      const rawTxBytes = ThetaJS.TxSigner.serializeTx(tx);
      const callResponse = await Api.callSmartContract({ data: rawTxBytes.toString('hex').slice(2) }, { network: Theta.chainId });
      let response;
      if (callResponse) {
        const callResponseJSON = await callResponse.json();
        const result = get(callResponseJSON, 'result');
        let outputValues = get(result, 'vm_return');
        const outputTypes = map(functionOutputs, ({ name, type }) => {
          return type;
        });
        outputValues = /^0x/i.test(outputValues) ? outputValues : '0x' + outputValues;
        let res = abiCoder.decode(outputTypes, outputValues)[0];
        response = {
          status: callResponse.status,
          body: res
        }
      }
      if (isResponseSuccessful(response)) {
        dispatch({
          type: `${baseAction}/SUCCESS`,
          response: response,
          metadata: metadata
        });

        if (onSuccess) {
          onSuccess(dispatch, response);
        }
      }
      else {
        dispatch({
          type: `${baseAction}/FAILURE`,
          response: null,
          metadata: metadata
        });

        if (onError) {
          onError(dispatch, response);
        }
      }
    } catch (e) {
      // Failed to parse
      dispatch({
        type: `${baseAction}/FAILURE`,
        response: null,
        metadata: metadata
      });

      if (onError) {
        onError(dispatch, null);
      }
    }
    finally {
      dispatch({
        type: `${baseAction}/END`,
        metadata: metadata
      });
    }
  };
}


export function smartContractFetchRPC(baseAction, metadata = {}, opts = {}) {
  console.log('in smartContractFetchRPC');
  let { onSuccess, onError } = opts;
  const { fn, inputValues, callData } = metadata;

  return async function (dispatch, getState) {
    dispatch({
      type: `${baseAction}/START`,
      metadata: metadata
    });

    let response;

    try {
      const res = await fn(...inputValues).call(callData);
      dispatch({
        type: `${baseAction}/SUCCESS`,
        response: {
          status: 200,
          body: res
        },
        metadata: metadata
      });

      if (onSuccess) {
        onSuccess(dispatch, response);
      }
    } catch (e) {
      // Failed to parse
      dispatch({
        type: `${baseAction}/FAILURE`,
        response: null,
        metadata: metadata
      });

      if (onError) {
        onError(dispatch, null);
      }
    }
    finally {
      dispatch({
        type: `${baseAction}/END`,
        metadata: metadata
      });
    }
  };
}

