export const ProposalActionMap = {
  'setStakingRewardPerBlock': 'Set Staking Reward Per Block',
  // 'setPrimaryMarketPlatformFeeSplitBasisPoints': 'Set Primary Market Platform FeeSplit Basis Points',
  // 'setSecondaryMarketPlatformFeeSplitBasisPoints': 'Set Secondary Market Platform Fee Split Basis Points',
  'updateLiquidityMiningParamAlpha': 'Update Liquidity Mining Param Alpha',
  'updateLiquidityMiningParamEpsilon': 'Update Liquidity Mining Param Epsilon',
  'updateLiquidityMiningParamGamma': 'Update Liquidity Mining Param Gamma',
  'updateLiquidityMiningParamMaxRewardPerTrade': 'Update Liquidity Mining Param Max Reward Per Trade',
  'updateLiquidityMiningParamOmega': 'Update Liquidity Mining Param Omega',
  'updateLiquidityMiningParamPriceThreshold': 'Update Liquidity Mining Param Price Threshold',
  'updateLiquidityMiningParams': 'Update Liquidity Mining Params'
}

// export const ProposalState = ['Pending', 'Active', 'Canceled', 'Defeated', 'Succeeded', 'Queued', 'Expired', 'Executed'];

export const ProposalStateText = {
  '0': 'PENDING',
  '1': 'Active',
  '2': 'Canceled',
  '3': 'Defeated',
  '4': 'Succeeded',
  '5': 'Queued',
  '6': 'Expired',
  '7': 'Executed'
}
export const ProposalState = {
  'PENDING': '0',
  'Active': '1',
  'Canceled': '2',
  'Defeated': '3',
  'Succeeded': '4',
  'Queued': '5',
  'Expired': '6',
  'Executed': '7'
}