import './VoteModal.scss';
import Modal from './Modal';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import { store } from '../state';
import { voteProposal } from '../state/actions/Proposal';
import { formatQuantity } from '../utils/Utils';
import config from '../Config';
import { useEffect } from 'react';
import bigNumber from 'bignumber.js';
import cx from 'classnames';
import { AiOutlineQuestionCircle } from "react-icons/ai";

const NETWROK = config.defaultThetaChainID;
const MIN_VOTE = 0;
const NOTE_TITLE = "To vote on a proposal, you'd need to delegate votes to your Metamask wallet before the proposal was created. This number represents the number of votes you had when the proposal was created and the number of votes you could vote on."

const VoteModal = props => {
  const { address, proposalId, voteSupport, isVotingProposal, startBlockVotes,
    provider, errMsg, isVoted, onClose } = props;

  const type = voteSupport === 'true' ? 'for' : 'against';
  const disabled = !startBlockVotes || bigNumber(startBlockVotes).lte(MIN_VOTE);

  useEffect(() => {
    let id;
    if (isVoted) {
      id = setTimeout(() => { onClose() }, 1500)
    }
    return () => clearTimeout(id);
  }, [isVoted, onClose])

  const handleVote = async () => {
    if (disabled) {
      return;
    }
    await store.dispatch(voteProposal(proposalId, address, voteSupport === 'true', NETWROK, provider));
  }

  return <Modal className="vote-modal" onClose={onClose}>
    <div className="modal-title">Vote {type} proposal {proposalId}</div>
    <div className="modal">
      <div className='vote-modal__votes'>{formatQuantity(startBlockVotes, 0, 2)} Votes</div>
      <div className='vote-modal__note' title={NOTE_TITLE}>
        <AiOutlineQuestionCircle />
        <p className='vote-modal__note--text'>The vote number doesn't look right?</p>
      </div>
      {errMsg && <div className='text-danger'>{errMsg}</div>}
      {isVotingProposal ? <div className='vote-modal--button in-process'>
        <Spinner className='xs' />
        Voting......
      </div> :
        isVoted ? <div className='vote-modal__button'>Voted {type} proposal {proposalId}!</div> :
          <div className={cx('vote-modal__button', { 'disabled': disabled })} onClick={handleVote}>
            {disabled ? 'You must have votes delegated to this address to cast a vote' :
              `Vote ${type} proposal ${proposalId}`}
          </div>}
    </div>
  </Modal>
}


const mapStateToProps = state => ({
  address: state.metamask.address,
  provider: state.metamask.provider,
  voteSupport: state.proposal.voteSupport,
  isVotingProposal: state.proposal.isVotingProposal,
  isVoted: state.proposal.isVoted,
  errMsg: state.proposal.errMsg
});

export default connect(mapStateToProps)(VoteModal);