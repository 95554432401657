import { useRef, useState } from 'react';
import './CreateProposalModal.scss';
import Modal from './Modal';
import { connect } from 'react-redux';
import web3 from '../utils/Web3';
import {
  GovernorAbi, GovernorAddress,
  getContractAddressByFunctionName, FunctionMap
} from '../constants/Contracts';
import config from '../Config';

const NETWROK = config.defaultThetaChainID;

const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[NETWROK]);

const CreateProposalModal = props => {
  const { address, pendingProposal, provider } = props;
  const pkRef = useRef();
  const [err, setErr] = useState('');


  const handleSubmit = async () => {
    try {
      const count = await web3.eth.getTransactionCount(address);
      const targets = [getContractAddressByFunctionName(pendingProposal.action, NETWROK)];
      const values = [0];
      const signatures = [""];
      const calldatas = [web3.eth.abi.encodeFunctionCall(FunctionMap[pendingProposal.action], [pendingProposal.value || 9])];
      const description = pendingProposal.description || "";

      let gas = await provider.request({
        method: 'eth_estimateGas',
        params: [{
          "from": address,
          "nonce": web3.utils.toHex(count),
          "to": GovernorAddress[NETWROK],
          "data": GovernorContract.methods.propose(targets, values, signatures, calldatas, description).encodeABI()
        }],
      });

      await provider.request({
        method: 'eth_sendTransaction',
        params: [{
          "from": address,
          "nonce": web3.utils.toHex(count),
          "gas": gas,
          "to": GovernorAddress[NETWROK],
          "data": GovernorContract.methods.propose(targets, values, signatures, calldatas, description).encodeABI()
        }],
      });

      if (err) setErr('');
    } catch (e) {
      console.log('err:', e)
      setErr(e.message);
    }
  }

  return <Modal className="create-proposal-modal" onClose={props.onClose}>
    <div className="modal-title">Please enter your private key</div>
    <div className="modal-hr"></div>
    <div className="modal">
      <textarea ref={pkRef} className="create-proposal-modal__input" />
      {err && <div className='text-danger'>{err}</div>}
      <div className='create-proposal-modal__submit' onClick={handleSubmit}>Submit your proposal</div>
    </div>
  </Modal>
}


const mapStateToProps = state => ({
  pendingProposal: state.proposal.pendingProposal,
});

export default connect(mapStateToProps)(CreateProposalModal);