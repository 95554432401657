import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from "./state";
import App from './App'
import { Provider as AlertProvider } from 'react-alert'
import Alerts from './services/Alerts'
import Alert from './components/Alert'
import { hot } from 'react-hot-loader/root'
import ProposalList from './components/ProposalList';
import Proposal from './components/Proposal';
import CreateProposal from './components/CreateProposal';
import history from './services/History';
// import Demo from './components/Demo';

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: 'top center',
  timeout: 5000,
  offset: '30px',
  transition: 'scale'
};

const AppWrapper = () => {
  const alertRef = Alerts.getRef();

  return (
    <Router history={history}>
      <Provider store={store}>
        <AlertProvider
          ref={alertRef}
          template={Alert}
          {...options}>
          <App>
            <Switch>
              <Route path="/proposal/:proposalId" component={Proposal} />
              <Route path="/create-proposal" component={CreateProposal} />
              {/* <Route path="/demo" component={Demo} /> */}
              <Route path="/" component={ProposalList} />
            </Switch>
          </App>
        </AlertProvider>
      </Provider>
    </Router>
  );
}

export default process.env.NODE_ENV === "development" ? hot(AppWrapper) : AppWrapper;

