import Networks, { NetworkIds, NetworkExplorerUrls, NetworkRPCUrls } from './constants/Networks'

const network = process.env.REACT_APP_NETWORK || Networks.THETA_PRIVATENET;

const config = {
  defaultThetaChainID: network,
  mainNetChainId: NetworkIds[network],
  explorerUrl: NetworkExplorerUrls[network],
  rpcUrl: NetworkRPCUrls[network]
};

export default config;
