import './ProposalList.scss';
import cx from 'classnames';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { store } from '../state';
import { fetchProposalList, reset } from '../state/actions/Proposal';
import { useEffect, useState } from 'react';
import { ProposalStateText } from '../constants/Proposal'
import { AiOutlineQuestionCircle } from "react-icons/ai";

const ProposalList = props => {
  const { proposalList, isFetchingProposalList } = props;
  const [showCancelled, setShowCancelled] = useState(false);
  const [pList, setPList] = useState(proposalList);

  useEffect(() => {
    store.dispatch(fetchProposalList());
    store.dispatch(reset());
  }, [])

  useEffect(() => {
    setPList(proposalList.filter(p => p.status !== '2'));
  }, [proposalList])

  const handleClick = function () {
    setPList(!showCancelled ? proposalList : proposalList.filter(p => p.status !== '2'));
    setShowCancelled(!showCancelled);
  }

  return <div className="proposal-list-container">
    <div className="proposal-list">
      <div className="proposal-list__note">
        <a href="https://thetalabs.medium.com/725c92e8a66e" target="_blank" rel="noreferrer">
          <AiOutlineQuestionCircle />
          <p className='proposal-list__note--text'>Learn how to participate in TDROP governance</p>
        </a>
      </div>
      <div className="proposal-list__header">
        <div className="proposal-list__header--title">Proposals</div>
        <div className="proposal-list__header--right">
          {isFetchingProposalList && <div className='spinner xs'></div>}
          <Link className="proposal-list__header--button" to="/create-proposal">
            Create Proposal
          </Link>
        </div>
      </div>
      <div className="proposal-list__switch">
        <div className="proposal-list__switch--title">Show Cancelled</div>
        <div className="proposal-list__switch--container">
          <label className="tdrop-switch lg" >
            <input type="checkbox" className="checkbox" checked={showCancelled} onChange={handleClick} />
            <span className="tdrop-slider lg"></span>
          </label>
        </div>
      </div>
      <div className="proposal-list__proposals">
        {proposalList.length === 0 && <div className="proposal-card empty">
          <div className='proposal-card__header'>No Proposals Found</div>
          <div className='proposal-card__content'>Proposals submitted by community members will appear here.</div>
        </div>}
        {pList.length > 0 && pList.map((proposal, i) => <ProposalCard proposal={proposal} key={i} />)}
      </div>
    </div>
  </div>
}

const ProposalCard = props => {
  const proposal = props.proposal;
  const status = ProposalStateText[proposal.status].toLocaleLowerCase();
  return <Link to={`/proposal/${proposal.id}`}>
    <div className="proposal-card">
      <span className="proposal-card__id">{proposal.id}</span>
      <span className="proposal-card__title">{proposal.title}</span>
      <span className={cx("proposal-card__status", status)}>{status}</span>
    </div>
  </Link>
}

const mapStateToProps = state => ({
  proposalList: state.proposal.proposalList,
  isFetchingProposalList: state.proposal.isFetchingProposalList
});

export default connect(mapStateToProps)(ProposalList);