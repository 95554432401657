import Modal from './Modal';
import './ActionModal.scss';
import { ProposalActionMap } from '../constants/Proposal';

const ActionModal = props => {
  
  const handleSelect = (e) => {
    props.onSelect(e);
    props.onClose();
  }

  return <Modal className="action-modal" onClose={props.onClose}>
    <div className="modal-title">Select an action</div>
    <div className="modal-hr"></div>
    <div className="modal">
      {Object.keys(ProposalActionMap).map((key, i) => {
        return <div key={i}
          onClick={handleSelect}
          className="action-modal__row"
          data-function={key}>{ProposalActionMap[key]}</div>
      })}
    </div>
  </Modal>
}

export default ActionModal;