import './NavBar.scss';
import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from "../state";
import { setAddress, setProvider, setNetworkId, fetchBalance } from "../state/actions/Metamask";
import { showModal, hideModal } from '../state/actions/Modal';
import AccountModal from './AccountModal';
import ErrorModal from './ErrorModal';
import ConnectWalletModal from './ConnectWalletModal';
import detectEthereumProvider from '@metamask/detect-provider'
import useIsMountedRef from '../hooks/useIsMountedRef';
import { truncateAddress, capitalize } from '../utils/Utils';
import Config from '../Config';
import { FaBookOpen, FaDiscord, FaInfoCircle } from "react-icons/fa";
import { formatQuantity } from '../utils/Utils';
import { AiOutlineEllipsis } from "react-icons/ai";

const NavBar = props => {
  const { networkId, provider, balance, address, modalType } = props;
  const isMountedRef = useIsMountedRef();
  const isCorrectNetwork = Config.mainNetChainId === networkId;
  const addressRef = useRef();
  const modalTypeRef = useRef();

  useEffect(() => {
    addressRef.current = address;
    modalTypeRef.current = modalType;
  })
  useEffect(() => {
    if (!provider) return;
    window.ethereum.on('accountsChanged', (accounts) => {
      const account = accounts[0];
      store.dispatch(setAddress(account));
      if (modalTypeRef.current === 'connect') {
        store.dispatch(showModal('connect'));
      }
      store.dispatch(fetchBalance(account));
    });
    window.ethereum.on('chainChanged', (chainId) => {
      store.dispatch(setNetworkId(chainId));
      let _isCorrectNetwork = chainId === Config.mainNetChainId;
      if (modalTypeRef.current === 'connect' && !_isCorrectNetwork) {
        store.dispatch(showModal('networkErr'));
      }
      if (modalTypeRef.current === 'account' && !_isCorrectNetwork) {
        store.dispatch(showModal('networkErr'));
      }
      if (modalTypeRef.current === 'networkErr' && _isCorrectNetwork) {
        if (addressRef.current == null) {
          store.dispatch(showModal('connect'));
        } else {
          store.dispatch(hideModal());
        }
      }
    });
  }, [provider, modalTypeRef])

  useEffect(() => {
    checkProvider();

    async function checkProvider() {
      const ethProvider = await detectEthereumProvider()
      if (!isMountedRef.current) return;
      if (ethProvider) {
        store.dispatch(setProvider(ethProvider));
        console.log('Ethereum successfully detected!')

        const chainId = await ethProvider.request({
          method: 'eth_chainId'
        })
        store.dispatch(setNetworkId(chainId));
      } else {
        // if (provider) setProvider(null);
        console.error('Please install MetaMask!')
      }
    }
  }, [isMountedRef])

  return <>
    <div className="nav-bar">
      <div className="nav-bar__logo">
        <Link to="/" className="theta-logo"></Link>
      </div>
      <div className="nav-bar__navs">
        <div className="nav-bar__navs--network">{Config.defaultThetaChainID}</div>
        <div className="nav-bar__navs--account">
          {!provider && <div className="account__connect" onClick={() => store.dispatch(showModal('connect'))}>Connect Wallet</div>}
          {provider && (isCorrectNetwork ? (address == null ?
            <div className="account__connect" onClick={() => store.dispatch(showModal('connect'))}>Connect Wallet</div> :
            <>
              <div className="account__balance">{formatQuantity(balance, 0, 2)} Votes</div>
              <div className="account__info" onClick={() => store.dispatch(showModal('account'))}> {truncateAddress(address)}</div>
            </>) : <div className='account__error' onClick={() => store.dispatch(showModal('networkErr'))}>Wrong Network</div>)}
        </div>
        <DropDown />
      </div>
    </div>
    {modalType === 'connect' && <ConnectWalletModal onClose={() => store.dispatch(hideModal())} />}
    {modalType === 'account' && <AccountModal onClose={() => store.dispatch(hideModal())} />}
    {modalType === 'networkErr' && <ErrorModal onClose={() => store.dispatch(hideModal())} title="Wrong Network" content={<WrongNetworkContent />} />}
  </>
}

const DropDown = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isExpanded && ref.current && !ref.current.contains(e.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isExpanded]);

  return <div ref={ref} >
    <div className="nav-bar__navs--ellipsis" onClick={() => setIsExpanded(!isExpanded)} >
      <AiOutlineEllipsis />
    </div>
    {isExpanded && <div className="drop-down">
      <a className="drop-down__row" href="https://www.thetatoken.org"
        target="_blank" rel="noopener noreferrer">
        <div className="drop-down__row--title">About</div>
        <div className="drop-down__row--icon"><FaInfoCircle /></div>
      </a>
      <a className="drop-down__row" href="https://discord.gg/vCXJd5YKDt"
        target="_blank" rel="noopener noreferrer">
        <div className="drop-down__row--title">Discord</div>
        <div className="drop-down__row--icon"><FaDiscord /></div>
      </a>
      <a className="drop-down__row" href="https://docs.thetatoken.org/"
        target="_blank" rel="noopener noreferrer">
        <div className="drop-down__row--title">Docs</div>
        <div className="drop-down__row--icon"><FaBookOpen /></div>
      </a>
    </div>}
  </div>
}

const _WrongNetworkContent = (props) => {
  const { provider } = props;
  const handleSwitch = async () => {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Config.mainNetChainId }],
      });
    } catch (e) {
      if (e.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Config.mainNetChainId,
                chainName: `Theta ${capitalize(Config.defaultThetaChainID)}`,
                rpcUrls: [Config.rpcUrl],
                blockExplorerUrls: [Config.explorerUrl],
                nativeCurrency: {
                  symbol: 'TFUEL',
                  decimals: 18
                }
              }
            ]
          })
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  return <div className='model-network-err'>
    <div className='model-network-err__title'>Please connect to Theta {capitalize(Config.defaultThetaChainID)} network.</div>
    <div className='model-network-err__button' onClick={handleSwitch}>
      Theta {capitalize(Config.defaultThetaChainID)}
    </div>
  </div>
}
const mapStateToPropsErrNetwork = state => ({
  provider: state.metamask.provider
});

const WrongNetworkContent = connect(mapStateToPropsErrNetwork)(_WrongNetworkContent);

const mapStateToProps = state => ({
  networkId: state.metamask.networkId,
  provider: state.metamask.provider,
  address: state.metamask.address,
  balance: state.metamask.balance,
  isConnected: state.metamask.isConnected,
  isFetchingBalances: state.metamask.isFetchingBalances,
  modalType: state.modal.type,
});

export default connect(mapStateToProps)(NavBar);