import {
  CREATE_PENDING_PROPOSAL, CREATE_PROPOSAL_END, CREATE_PROPOSAL_FAILURE, CREATE_PROPOSAL_START, CREATE_PROPOSAL_SUCCESS,
  FETCH_PROPOSAL, FETCH_PROPOSAL_LIST, SET_PENDINGPROPOSAL, SET_VOTESUPPORT, VOTE_PROPOSAL_END, VOTE_PROPOSAL_FAILURE,
  VOTE_PROPOSAL_START, RESET_ERROR_MSG, CANCEL_PROPOSAL_START, CANCEL_PROPOSAL_FAILURE, CANCEL_PROPOSAL_END,
  VOTE_PROPOSAL_SUCCESS, CANCEL_PROPOSAL_SUCCESS, RESET, UPDATE_PROPOSAL_STATE_START, UPDATE_PROPOSAL_STATE_SUCCESS,
  UPDATE_PROPOSAL_STATE_FAILURE, UPDATE_PROPOSAL_STATE_END, QUEUE_PROPOSAL_START, QUEUE_PROPOSAL_FAILURE,
  QUEUE_PROPOSAL_SUCCESS, QUEUE_PROPOSAL_END, EXECUTE_PROPOSAL_START, EXECUTE_PROPOSAL_FAILURE, EXECUTE_PROPOSAL_SUCCESS,
  EXECUTE_PROPOSAL_END
} from '../types/Proposal';
import { reduxFetch } from './Api'
import Api from '../../services/Api';
import web3 from '../../utils/Web3';
import { sleep, getHex } from '../../utils/Utils';
import {
  GovernorAbi, GovernorAddress,
  getContractAddressByFunctionName, FunctionMap,
} from '../../constants/Contracts';
import get from 'lodash/get';
import { ethers } from "ethers";
import { HIDE_MODAL } from '../types/Modal';


export function fetchProposalList() {
  return reduxFetch(FETCH_PROPOSAL_LIST, function () {
    return Api.fetchProposalList();
  });
}

export function fetchProposal(pId) {
  return reduxFetch(FETCH_PROPOSAL, function () {
    return Api.fetchProposal(pId);
  });
}

export function setPendingProposal(pendingProposal) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_PENDINGPROPOSAL,
      pendingProposal: pendingProposal
    })
  }
}

export function createPendingProposal(proposal) {
  return reduxFetch(CREATE_PENDING_PROPOSAL, function () {
    return Api.createPendingProposal(proposal)
  })
}

export async function createProposalAsync(dispatch, proposal, network, provider) {
  const { description, action, proposer, values } = proposal;
  const targets = [getContractAddressByFunctionName(action, network)];
  proposal.targets = targets;
  let address = proposer;
  let hasErr = false;
  dispatch({
    type: CREATE_PROPOSAL_START,
    metadata: {
      proposalData: proposal
    }
  });
  try {
    await Api.createPendingProposal(proposal);
  } catch (e) {
    dispatch({
      type: CREATE_PROPOSAL_FAILURE,
      errMsg: 'Create pending proposal failed.'
    });
    hasErr = true;
  }
  if (hasErr) {
    return;
  }

  try {
    const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[network]);
    const count = await web3.eth.getTransactionCount(address);
    const signatures = [""];
    const calldatas = [web3.eth.abi.encodeFunctionCall(FunctionMap[action], values)];


    let gas = await provider.request({
      method: 'eth_estimateGas',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.propose(targets, [0], signatures, calldatas, description).encodeABI()
      }],
    });

    const txHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "gas": gas,
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.propose(targets, [0], signatures, calldatas, description).encodeABI()
      }],
    });

    const txRes = await _fetchTransaction(txHash, 1);

    const tx = await txRes.json();

    const err = get(tx, 'body.receipt.EvmErr');
    if (err) {
      let evmRetText = Buffer.from(get(tx, 'body.receipt.EvmRet'), 'base64').toString();
      evmRetText = evmRetText.replace('\u0000', "").split('GovernorAlpha::');
      const evmRet = evmRetText[1];
      const errMsg = err + '. Detail: ' + evmRet;
      dispatch({
        type: CREATE_PROPOSAL_FAILURE,
        errMsg
      });
      return;
    }

    const iface = new ethers.utils.Interface(GovernorAbi);
    let logs = get(tx, 'body.receipt.Logs');
    logs = JSON.parse(JSON.stringify(logs));
    // Only log of transfer event remains
    logs = logs.map(obj => {
      obj.data = getHex(obj.data);
      return obj;
    })
    let decode;
    for (let log of logs) {
      let event = null;
      for (let i = 0; i < GovernorAbi.length; i++) {
        let item = GovernorAbi[i];
        if (item.name !== "ProposalCreated") continue;
        const hash = iface.getEventTopic(item.name)
        if (hash === log.topics[0]) {
          event = item;
          break;
        }
      }
      if (event != null) {
        let bigNumberData = iface.decodeEventLog(event.name, log.data, log.topics);
        let data = {};
        Object.keys(bigNumberData).forEach(k => {
          data[k] = bigNumberData[k].toString();
        })
        decode = {
          result: data,
          eventName: event.name,
          event: event
        }
      }
    }

    const id = get(decode, 'result.id');

    let state = await GovernorContract.methods.state(id).call();

    let res = await Api.createProposal({
      description: proposal.description,
      title: proposal.title,
      targets: targets,
      action: proposal.action,
      values: values,
      proposer: proposal.proposer,
      createTimestamp: get(tx, 'body.timestamp'),
      id: id,
      status: state
    });

    let resJSON = await res.json();

    console.log('resJSON in create proposal:', resJSON);

    dispatch({
      type: CREATE_PROPOSAL_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: CREATE_PROPOSAL_FAILURE,
      errMsg: e.message
    });
  } finally {
    dispatch({
      type: CREATE_PROPOSAL_END,
    });
    return Promise.resolve(null);
  }
}

export function createProposal(proposal, network, provider) {
  return function (dispatch, getState) {
    createProposalAsync(dispatch, proposal, network, provider).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export async function voteProposalAsync(dispatch, proposalId, address, support, network, provider) {
  dispatch({
    type: VOTE_PROPOSAL_START
  });
  try {
    const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[network]);
    const count = await web3.eth.getTransactionCount(address);
    let gas = web3.utils.toHex(100000);
    try {
      gas = await provider.request({
        method: 'eth_estimateGas',
        params: [{
          "from": address,
          "nonce": web3.utils.toHex(count),
          "to": GovernorAddress[network],
          "data": GovernorContract.methods.castVote(proposalId, support).encodeABI()
        }],
      });
    } catch (e) {
      console.log('estimateGas in vote failed, error:', e.message);
    }

    const txHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "gas": gas,
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.castVote(proposalId, support).encodeABI()
      }],
    });

    const txRes = await _fetchTransaction(txHash, 1);

    const tx = await txRes.json();

    const err = get(tx, 'body.receipt.EvmErr');
    if (err) {
      let evmRetText = Buffer.from(get(tx, 'body.receipt.EvmRet'), 'base64').toString();
      evmRetText = evmRetText.replace('\u0000', "").split('GovernorAlpha::');
      const evmRet = evmRetText[1];
      const errMsg = err + '. Detail: ' + evmRet;
      dispatch({
        type: VOTE_PROPOSAL_FAILURE,
        errMsg
      });
      return;
    }

    dispatch({
      type: VOTE_PROPOSAL_SUCCESS
    })
  } catch (e) {
    dispatch({
      type: VOTE_PROPOSAL_FAILURE,
      errMsg: e.message
    })
  } finally {
    dispatch({
      type: VOTE_PROPOSAL_END
    })
  }
}

export function voteProposal(proposalId, address, support, network, provider) {
  return function (dispatch, getState) {
    voteProposalAsync(dispatch, proposalId, address, support, network, provider).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export async function cancelProposalAsync(dispatch, proposalId, address, network, provider) {
  dispatch({
    type: CANCEL_PROPOSAL_START
  });
  try {
    const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[network]);
    const count = await web3.eth.getTransactionCount(address);

    let gas = await provider.request({
      method: 'eth_estimateGas',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.cancel(proposalId).encodeABI()
      }],
    });

    const txHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "gas": gas,
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.cancel(proposalId).encodeABI()
      }],
    });

    const txRes = await _fetchTransaction(txHash, 1);

    if (txRes === undefined) {
      dispatch({
        type: CANCEL_PROPOSAL_FAILURE,
        errMsg: "Transaction haven't been processed yet."
      });
      return;
    }

    const tx = await txRes.json();

    const err = get(tx, 'body.receipt.EvmErr');
    if (err) {
      let evmRetText = Buffer.from(get(tx, 'body.receipt.EvmRet'), 'base64').toString();
      evmRetText = evmRetText.replace('\u0000', "").split('GovernorAlpha::');
      const evmRet = evmRetText[1];
      const errMsg = err + '. Detail: ' + evmRet;
      dispatch({
        type: CANCEL_PROPOSAL_FAILURE,
        errMsg
      });
      return;
    }

    let state = await GovernorContract.methods.state(proposalId).call();

    let res = await Api.updateProposalStatus(proposalId, state);

    let resJSON = await res.json();

    console.log('resJSON in cancel proposal:', resJSON);

    dispatch({
      type: CANCEL_PROPOSAL_SUCCESS,
    })
    dispatch({
      type: HIDE_MODAL
    })
  } catch (e) {
    dispatch({
      type: CANCEL_PROPOSAL_FAILURE,
      errMsg: e.message
    })
  } finally {
    dispatch({
      type: CANCEL_PROPOSAL_END
    })
  }
}

export function cancelProposal(proposalId, address, network, provider) {
  return function (dispatch, getState) {
    cancelProposalAsync(dispatch, proposalId, address, network, provider).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export async function _fetchTransaction(hash, times) {
  if (times > 6) return;
  await sleep(6000);
  return Api.fetchTransactionById(hash).then(res => {
    if (res.status === 200) {
      return res;
    } else {
      return _fetchTransaction(hash, times + 1);
    }
  }).catch(async e => {
    console.log('Error in _fetchTransaction hash:', hash);
    return _fetchTransaction(hash, times + 1);
  })
}

export async function updateStateAsync(dispatch, proposalId, state) {
  dispatch({
    type: UPDATE_PROPOSAL_STATE_START
  });
  try {
    let res = await Api.updateProposalStatus(proposalId, state);

    let resJSON = await res.json();

    console.log('resJSON in update proposal state:', resJSON);

    dispatch({
      type: UPDATE_PROPOSAL_STATE_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: UPDATE_PROPOSAL_STATE_FAILURE,
      errMsg: e.message
    })
  } finally {
    dispatch({
      type: UPDATE_PROPOSAL_STATE_END
    })
  }
}

export function updateState(proposalId, state) {
  return function (dispatch, getState) {
    updateStateAsync(dispatch, proposalId, state).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export async function queueProposalAsync(dispatch, proposalId, address, network, provider) {
  dispatch({
    type: QUEUE_PROPOSAL_START
  });
  try {
    const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[network]);

    const count = await web3.eth.getTransactionCount(address);

    let gas = await provider.request({
      method: 'eth_estimateGas',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.queue(proposalId).encodeABI()
      }],
    });

    const txHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "gas": gas,
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.queue(proposalId).encodeABI()
      }],
    });

    const txRes = await _fetchTransaction(txHash, 1);

    if (txRes === undefined) {
      dispatch({
        type: QUEUE_PROPOSAL_FAILURE,
        errMsg: "Transaction haven't been processed yet."
      });
      return;
    }

    const tx = await txRes.json();

    const err = get(tx, 'body.receipt.EvmErr');
    if (err) {
      let evmRetText = Buffer.from(get(tx, 'body.receipt.EvmRet'), 'base64').toString();
      evmRetText = evmRetText.replace('\u0000', "").split('GovernorAlpha::');
      const evmRet = evmRetText[1];
      let errMsg = err + '.';
      if (evmRet) {
        errMsg += ' Detail: ' + evmRet;
      }
      dispatch({
        type: QUEUE_PROPOSAL_FAILURE,
        errMsg
      });
      return;
    }

    let state = await GovernorContract.methods.state(proposalId).call();

    let res = await Api.updateProposalStatus(proposalId, state);

    let resJSON = await res.json();

    console.log('resJSON in queue proposal:', resJSON);

    dispatch({
      type: QUEUE_PROPOSAL_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: QUEUE_PROPOSAL_FAILURE,
      errMsg: e.message
    })
  } finally {
    dispatch({
      type: QUEUE_PROPOSAL_END
    })
  }
}

export function queueProposal(proposalId, address, network, provider) {
  return function (dispatch, getState) {
    queueProposalAsync(dispatch, proposalId, address, network, provider).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export async function executeProposalAsync(dispatch, proposalId, address, network, provider) {
  dispatch({
    type: EXECUTE_PROPOSAL_START
  });
  try {
    const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[network]);

    const count = await web3.eth.getTransactionCount(address);

    let gas = await provider.request({
      method: 'eth_estimateGas',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.execute(proposalId).encodeABI()
      }],
    });

    const txHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [{
        "from": address,
        "nonce": web3.utils.toHex(count),
        "gas": gas,
        "to": GovernorAddress[network],
        "data": GovernorContract.methods.execute(proposalId).encodeABI()
      }],
    });

    const txRes = await _fetchTransaction(txHash, 1);


    if (txRes === undefined) {
      dispatch({
        type: EXECUTE_PROPOSAL_FAILURE,
        errMsg: "Transaction haven't been processed yet."
      });
      return;
    }

    const tx = await txRes.json();

    const err = get(tx, 'body.receipt.EvmErr');
    if (err) {
      let evmRetText = Buffer.from(get(tx, 'body.receipt.EvmRet'), 'base64').toString();
      evmRetText = evmRetText.replace('\u0000', "").split('GovernorAlpha::');
      const evmRet = evmRetText[1];
      let errMsg = err + '.';
      if (evmRet) {
        errMsg += ' Detail: ' + evmRet;
      }
      dispatch({
        type: EXECUTE_PROPOSAL_FAILURE,
        errMsg
      });
      return;
    }

    let state = await GovernorContract.methods.state(proposalId).call();

    let res = await Api.updateProposalStatus(proposalId, state);

    let resJSON = await res.json();

    console.log('resJSON in execute proposal:', resJSON);

    dispatch({
      type: EXECUTE_PROPOSAL_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: EXECUTE_PROPOSAL_FAILURE,
      errMsg: e.message
    })
  } finally {
    dispatch({
      type: EXECUTE_PROPOSAL_END
    })
  }
}

export function executeProposal(proposalId, address, network, provider) {
  return function (dispatch, getState) {
    executeProposalAsync(dispatch, proposalId, address, network, provider).then(function (thunk) {
      if (thunk) {
        dispatch(thunk);
      }
    });
  };
}

export function setVoteSupport(support) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_VOTESUPPORT,
      voteSupport: support
    })
  }
}

export function resetErrMsg() {
  return function (dispatch, getState) {
    dispatch({
      type: RESET_ERROR_MSG,
    })
  }
}

export function reset() {
  return function (dispatch, getState) {
    dispatch({
      type: RESET,
    })
  }
}