import { useEffect } from 'react';
import cx from 'classnames';
import './Proposal.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from '../state';
import { fetchProposal, setVoteSupport, reset, updateState, queueProposal, executeProposal } from '../state/actions/Proposal';
import { showModal, hideModal } from '../state/actions/Modal';
import config from '../Config';
import { ProposalStateText, ProposalState } from '../constants/Proposal'
import { useState, useRef } from 'react';
import web3 from '../utils/Web3';
import { formatQuantity, date } from '../utils/Utils';
import {
  GovernorAbi, GovernorAddress, TDropStakingAbi, TDropStakingAddress,
} from '../constants/Contracts';
import { NetworkBlockPeriod } from '../constants/Networks';
import { WEI } from '../constants/Utils';
import bigNumber from 'bignumber.js';
import VoteModal from './VoteModal';
import CancelModal from './CancelModal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Spinner from './Spinner';
import { TbArrowBigLeft } from "react-icons/tb";

const NETWROK = config.defaultThetaChainID;
const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[NETWROK]);
const TDropStakingContract = new web3.eth.Contract(TDropStakingAbi, TDropStakingAddress[NETWROK]);
const BlockPeriod = NetworkBlockPeriod[NETWROK];

const Proposal = props => {
  const { proposalId } = props.match.params;
  const { proposal, address, modalType, isVoted, isCancelled,
    isUpdatedState, errMsg, isQueued, isExecuted } = props;
  const status = (ProposalStateText[proposal.status] || "").toLocaleLowerCase();
  const [detail, setDetail] = useState({ forVotes: 0, againstVotes: 0, quorum: 0 });
  const [startBlockVotes, setStartBlockVotes] = useState(0);
  const forRef = useRef();
  const againstRef = useRef();

  useEffect(() => {
    store.dispatch(reset());
  }, [])

  useEffect(() => {
    store.dispatch(fetchProposal(proposalId))
  }, [proposalId, isVoted, isCancelled, isUpdatedState, isQueued, isExecuted])

  useEffect(() => {
    fetchProposal(proposalId);
    async function fetchProposal(id) {
      if (id !== proposal.id) return;
      let quorum = await GovernorContract.methods.quorumVotes().call();
      let _proposal = await GovernorContract.methods.proposals(id).call();
      let state = await GovernorContract.methods.state(id).call();
      if (proposal.status && state !== proposal.status && id === proposal.id) {
        store.dispatch(updateState(proposalId, state))
      }
      // const endTimestamp = (Number(_proposal.endBlock) - Number(_proposal.startBlock)) * 1 * 1000 + Number(proposal.create_timestamp + '000');
      // for mainnet and testnet
      const endTimestamp = (Number(_proposal.endBlock) - Number(_proposal.startBlock)) * BlockPeriod * 1000 + Number(proposal.create_timestamp + '000');
      const endTimeText = date(endTimestamp);
      if (address) {
        const votesWei = await TDropStakingContract.methods.getPriorVotes(address, _proposal.startBlock).call();
        const votes = new bigNumber(votesWei).dividedBy(WEI).toFixed(2);
        setStartBlockVotes(votes);
      }
      setDetail({
        forVotes: _proposal.forVotes,
        againstVotes: _proposal.againstVotes,
        quorum: quorum,
        endBlock: _proposal.endBlock,
        endTimeText,
        eta: Number(_proposal.eta || 0),
        startBlock: _proposal.startBlock
      })
      let sum = bigNumber.sum(_proposal.forVotes, _proposal.againstVotes);
      if (!sum.isEqualTo(0) && forRef.current && againstRef.current) {
        let forPercent = new bigNumber(_proposal.forVotes).times(100).dividedToIntegerBy(sum);
        let againstPercent = 100 - forPercent;
        forRef.current.style.width = forPercent + '%';
        againstRef.current.style.width = againstPercent + '%';
      }
    }
  }, [proposalId, isVoted, isCancelled, proposal, isQueued, isExecuted, address])

  const handleVote = async e => {
    const support = e.target.dataset['support'];
    store.dispatch(reset());
    if (!address) {
      store.dispatch(showModal('connect'));
      return;
    }
    await store.dispatch(setVoteSupport(support));
    store.dispatch(showModal('vote'))
  }

  const handleClose = () => {
    store.dispatch(hideModal())
    store.dispatch(reset());
  }

  const handleOpenCancelModal = () => {
    store.dispatch(reset());
    store.dispatch(showModal('cancel'))
  }

  return <><div className="proposal-container">
    < div className="proposal" >
      <div className="proposal__header">
        <Link className="proposal__header--back-button" to="/">
          <TbArrowBigLeft />
          <p className='proposal__header--back-button text'>All Proposals</p>
        </Link>
        <span className={cx("proposal__header--status", status)}>{status}</span>
      </div>
      <div className="proposal__title">{proposal.title}</div>
      Voting ended at block {detail.endBlock} (Est. {detail.endTimeText})
      <div className="proposal__voting">
        <div className='proposal__voting--wrap'>
          {proposal.status === ProposalState.Active &&
            <div className='proposal__voting--button' data-support="true" onClick={handleVote}>
              Vote For
            </div>}
          <div className='proposal__voting--result-wrap'>
            <div className='proposal__voting--result'>
              <div>For</div>
              <div>{formatQuantity(detail.forVotes, 18, 2)} / <span className='proposal__voting--quorum'>{formatQuantity(detail.quorum, 18, 2)}</span></div>
            </div>
            <div className="proposal__voting--progress">
              <div className='proposal__voting--for-percent' ref={forRef}></div>
            </div>
          </div>
        </div>
        <div className='proposal__voting--wrap'>
          {proposal.status === ProposalState.Active &&
            <div className='proposal__voting--button' data-support="false" onClick={handleVote}>
              Vote Against
            </div>}
          <div className='proposal__voting--result-wrap'>
            <div className='proposal__voting--result'>
              <div>Against</div>
              <div>{formatQuantity(detail.againstVotes, 18, 2)}</div>
            </div>
            <div className="proposal__voting--progress">
              <div className='proposal__voting--against-percent' ref={againstRef}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="proposal__detail">
        <div className="proposal__section-header">Details</div>
        <Details proposal={proposal} />
      </div>
      <div className="proposal__description">
        <div className="proposal__section-header">Description</div>
        <ReactMarkdown
          children={'# ' + proposal.title + '\n' + proposal.description}
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            }
          }} />
      </div>
      <div className="proposal__proposer">
        <div className="proposal__section-header">Proposer</div>
        <a className="proposal__proposer--address"
          href={`${config.explorerUrl}/address/${proposal.proposer}`}
          target="_blank"
          rel="noopener noreferrer">{proposal.proposer}</a>
      </div>
      {errMsg && <div className='proposal__error-msg'>Error: {errMsg}</div>}
      {proposal.proposer === address && proposal.status !== ProposalState.Executed && proposal.status !== ProposalState.Canceled && <div className="proposal__cancel" onClick={handleOpenCancelModal}>
        Cancel this proposal
      </div>}
      {proposal.status === ProposalState.Succeeded && address && <Queue proposalId={proposalId} />}
      {proposal.status === ProposalState.Queued && address && <Execute proposalId={proposalId} eta={detail.eta} />}
    </div >
  </div >
    {modalType === 'vote' && <VoteModal proposalId={proposalId} onClose={handleClose} startBlockVotes={startBlockVotes} />}
    {modalType === 'cancel' && <CancelModal proposalId={proposalId} onClose={handleClose} />}
  </>
}

const mapStateToProps = state => ({
  proposal: state.proposal.proposal,
  address: state.metamask.address,
  modalType: state.modal.type,
  isVoted: state.proposal.isVoted,
  isCancelled: state.proposal.isCancelled,
  isUpdatedState: state.proposal.isUpdatedState,
  isQueued: state.proposal.isQueued,
  isExecuted: state.proposal.isExecuted,
  errMsg: state.proposal.errMsg
});

export default connect(mapStateToProps)(Proposal);

const Details = props => {
  const { proposal } = props;
  return proposal.targets ? proposal.targets.map((address, i) => {
    return <div key={i}>{i + 1}:<a className="proposal__proposer--address"
      href={`${config.explorerUrl}/address/${address}`}
      target="_blank"
      rel="noopener noreferrer">{address}</a>.{proposal.action}({proposal.values.map(value => value)})</div>
  }) : <></>
}

const _Queue = props => {
  const { proposalId, isQueued, isQueuing, address, provider } = props;

  const handleQueue = () => {
    store.dispatch(queueProposal(proposalId, address, NETWROK, provider));
  }

  return (isQueuing) ?
    <div className='proposal__queue in-process'>
      <Spinner className='xs' />
      Queuing proposal {proposalId}......
    </div> : isQueued ? <div className='proposal__queue success'>
      Queued the proposal {proposalId}, refreshing...
    </div> :
      <div className={cx("proposal__queue")} onClick={handleQueue}>
        Queue Proposal {proposalId}
      </div>
}

const mapStateToPropsQueue = state => ({
  isQueued: state.proposal.isQueued,
  isQueuing: state.proposal.isQueuing,
  address: state.metamask.address,
  provider: state.metamask.provider,
});

const Queue = connect(mapStateToPropsQueue)(_Queue);


const _Execute = props => {
  const { proposalId, isExecuted, isExecuting, address, provider, eta = 0 } = props;
  const curTime = Math.ceil(+new Date() / 1000);

  const handleExecute = () => {
    store.dispatch(executeProposal(proposalId, address, NETWROK, provider));
  }

  return (isExecuting) ?
    <div className='proposal__execute in-process'>
      <Spinner className='xs' />
      Executed proposal {proposalId}......
    </div> : isExecuted ? <div className='proposal__execute success'>
      Executed proposal {proposalId}, refreshing...
    </div> : curTime > Number(eta) ?
      <div className={cx("proposal__execute")} onClick={handleExecute}>
        Execute Proposal {proposalId}
      </div> : <div className='proposal__execute disabled'>Please wait until {date(eta * 1000)} to execute the proposal</div>
}

const mapStateToPropsExecute = state => ({
  isExecuted: state.proposal.isExecuted,
  isExecuting: state.proposal.isExecuting,
  address: state.metamask.address,
  provider: state.metamask.provider,
});

const Execute = connect(mapStateToPropsExecute)(_Execute);