import './CreateProposal.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import ActionModal from './ActionModal';
import { useEffect, useRef, useState } from 'react';
import { ProposalActionMap } from '../constants/Proposal';
import { connect } from 'react-redux';
import CreateProposalModal from './CreateProposalModal';
import useIsMountedRef from '../hooks/useIsMountedRef';
import history from '../services/History';
import web3 from '../utils/Web3';
import { store } from '../state';
import { createProposal, reset } from '../state/actions/Proposal';
import { formatQuantity, getAmount } from '../utils/Utils';
import {
  GovernorAbi, GovernorAddress, FunctionMap,
} from '../constants/Contracts';
import config from '../Config';
import get from 'lodash/get';
import Spinner from './Spinner';
import bigNumber from 'bignumber.js';


const NETWORK = config.defaultThetaChainID;

const GovernorContract = new web3.eth.Contract(GovernorAbi, GovernorAddress[NETWORK]);

const tipString = "Tip: Select an action and describe your proposal for the community. The proposal cannot be modified after submission, so please verify all information before submitting. The voting period will begin immediately and last for 7 days.";

const CreateProposal = props => {
  const { balance, address, provider, isCreatingPendingProposal, isCreatingProposal, errMsg, isCreatedProposal } = props;
  const [showActionModal, setShowActionModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [action, setAction] = useState('setStakingRewardPerBlock');
  const [proposalThreshold, setProposalThreshold] = useState(0);
  const disabled = !balance || bigNumber(balance).lt(proposalThreshold);
  const buttonText = disabled ? `You must have ${formatQuantity(proposalThreshold, 0, 2)} votes to submit a proposal` : 'Submit your proposal';
  const isMountedRef = useIsMountedRef();
  const descriptionRef = useRef();
  const titleRef = useRef();
  const inputsRef = useRef({});

  useEffect(() => {
    fetchProposalThreshold();
    async function fetchProposalThreshold() {
      let proposalThresholdWei = await GovernorContract.methods.proposalThreshold().call();
      let proposalThreshold = getAmount(proposalThresholdWei, 18);
      if (!isMountedRef.current) return;
      setProposalThreshold(proposalThreshold);
    }
  }, [isMountedRef])

  useEffect(() => {
    let id;
    if (isCreatedProposal) {
      id = setTimeout(() => routerToProposal(), 1500);
    }
    async function routerToProposal() {
      let lastId = await GovernorContract.methods.proposalCount().call();
      store.dispatch(reset());
      history.push(`/proposal/${lastId}`);
    }
    return () => clearTimeout(id);
  }, [isCreatedProposal])

  const handleOnSelect = e => {
    const key = e.target.dataset.function;
    setAction(key);
  }

  const handleSubmit = async () => {
    if (disabled) return;

    const description = descriptionRef.current.value || "";
    const title = titleRef.current.value;
    const values = Object.keys(inputsRef.current).map(k => inputsRef.current[k].value);

    await store.dispatch(createProposal({
      description, title, action, values,
      proposer: address
    }, NETWORK, provider));
  }

  return <div className="create-proposal-container">
    <div className="create-proposal" >
      <div className="create-proposal__header">
        <Link className="create-proposal__header--back-button" to="/"></Link>
        <span className="create-proposal__header--title">Create Proposal</span>
        <span></span>
      </div>
      <div className="create-proposal__tips">{tipString}</div>
      <div className="create-proposal__action">
        <div className="create-proposal__action--title">Proposed Action</div>
        <div className="create-proposal__action--value" onClick={() => setShowActionModal(true)}>{ProposalActionMap[action]}</div>
      </div>
      <Inputs inputsRef={inputsRef} action={action} />
      <div className="create-proposal__proposal">
        <div className="create-proposal__proposal--title">Proposal</div>
        <div className="create-proposal__proposal--value">
          <input type="text" placeholder='Proposal Title' ref={titleRef} />
          <textarea ref={descriptionRef}
            placeholder="## Summary&#13;&#13;Insert your summary here&#13;&#13;## Methodology&#13;&#13;Insert your methodology here&#13;&#13;## Conclusion&#13;&#13;Insert your conclusion here" />
        </div>
      </div>
      {errMsg && <div className='text-danger create-proposal__error'>Error: {errMsg}</div>}
      {(isCreatingProposal || isCreatingPendingProposal) ?
        <div className='create-proposal__submit in-process'>
          <Spinner className='xs' />
          Creating proposal......
        </div> : isCreatedProposal ? <div className='create-proposal__submit success'>
          Submitted the proposal, redirecting...
        </div> :
          <div className={cx("create-proposal__submit", { 'disabled': disabled })} onClick={handleSubmit}>
            {buttonText}
          </div>}
    </div>
    {showActionModal && <ActionModal onClose={() => setShowActionModal(false)} onSelect={handleOnSelect} />}
    {showCreateModal && <CreateProposalModal onClose={() => setShowCreateModal(false)} />}
  </div >
}

const mapStateToProps = state => ({
  balance: state.metamask.balance,
  address: state.metamask.address,
  provider: state.metamask.provider,
  isCreatedPendingProposal: state.proposal.isCreatedPendingProposal,
  isCreatingProposal: state.proposal.isCreatingProposal,
  isCreatingPendingProposal: state.proposal.isCreatingPendingProposal,
  isCreatedProposal: state.proposal.isCreatedProposal,
  errMsg: state.proposal.errMsg
});

export default connect(mapStateToProps)(CreateProposal);

const Inputs = (props) => {
  const { action, inputsRef } = props;
  const inputs = get(FunctionMap[action], 'inputs')
  return inputs.map((value, index) => {
    const { name, type } = value;
    const argName = `arg${index}`;
    const key = `inputs.${argName}`;
    return (
      <div className="create-proposal__input" key={key}>
        <div className="create-proposal__input--title">{name + " (" + type + ")"}</div>
        <input className="create-proposal__input--input"
          placeholder={"Enter " + name}
          name={key}
          ref={e => inputsRef.current[argName] = e}
        />
      </div>
    );
  })
}