import './CancelModal.scss';
import Modal from './Modal';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import { store } from '../state';
import { cancelProposal } from '../state/actions/Proposal';
import config from '../Config';
import bigNumber from 'bignumber.js';

const NETWROK = config.defaultThetaChainID;

const VoteModal = props => {
  const { address, proposalId, isCancelingProposal, provider, errMsg, onClose, balance } = props;

  const disabled = balance && bigNumber(balance).gt(0);

  const handleCancel = async () => {
    await store.dispatch(cancelProposal(proposalId, address, NETWROK, provider));
  }

  return <Modal className="cancel-modal" onClose={onClose}>
    <div className="modal-title">Really want to cancel proposal {proposalId}?</div>
    <div className="modal">
      {errMsg && <div className='text-danger'>{errMsg}</div>}
      {isCancelingProposal ? <div className='cancel-modal__button in-process'>
        <Spinner className='xs' />
        Canceling.....
      </div> : disabled ? <div className='cancel-modal__button disabled'>
        You Must Have 0 Votes To Cancel
      </div> : <div className='cancel-modal__button cancel' onClick={handleCancel}>
        Cancel Proposal
      </div>}
    </div>
  </Modal>
}


const mapStateToProps = state => ({
  address: state.metamask.address,
  provider: state.metamask.provider,
  isCancelingProposal: state.proposal.isCancelingProposal,
  errMsg: state.proposal.errMsg,
  balance: state.metamask.balance
});

export default connect(mapStateToProps)(VoteModal);